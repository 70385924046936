<template>
    <div>
        <van-nav-bar title="白名单客户赠险领取" left-text="返回" left-arrow @click-left="onClickLeft" />

        <van-form @submit="submit">
            <van-cell-group inset>
                <van-field v-model="params.name" label="姓名" name="pattern" placeholder="请填写姓名"
                    :rules="[{ required: true, message: '请填写姓名' }]" />
                <van-field v-model="params.mobile" label="手机号" name="validator" placeholder="请填写手机号" :rules="[{ required: true, pattern: /^1[3|4|5|6|7|8|9][0-9]{9}$/, message: '请填写正确的手机号' },
                { required: true, message: '请填写手机号' }]" />
                <van-field v-model="params.idCard" label="身份证" name="validatorMessage" placeholder="请填写身份证号" :rules="[{
                    pattern: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
                    message: '请填写正确的身份证号'
                },
                { required: true, message: '请填写身份证号' }]" />
            </van-cell-group>
            <div style="margin: 16px;">
                <van-button round block type="primary" native-type="submit">
                    提交
                </van-button>
            </div>
        </van-form>
        <van-dialog v-model:show="show" title="添加业务人员微信领取" show-cancel-button @confirm="encrypt()">
            <van-image width="10rem" height="10rem" fit="cover" position="left" :src="reqData.qrCode" />
        </van-dialog>
    </div>
</template>
<script>
import { Toast } from 'vant';
import indexApi from '@/api/index'
export default {
    components: {
        Toast
    },
    props: {},
    data() {
        return {
            params: {
                name: '',
                mobile: '',
                idCard: '',
                baseId: this.$route.query.baseId
            },
            reqData:{

            },
            qrCodeParam: {
                activity: ''
            },
            encryptObj: null,
            show: false
        }
    },
    computed: {},
    created() {

    },
    methods: {
        onClickLeft() {
            history.back();
        },
        async submit() {
            console.log('提交验证', this.params)
            const req = await indexApi.verifyReceiveInfo(this.params)
            if (req.data.code==200) {
                Toast({
                    type: 'success',
                    message: "信息验证通过"
                })
                this.reqData = req.data.data
                this.show = true
            }else{
                Toast( req.data.msg )
            }
        },
        //将信息进行加密处理后进行页面跳转
        async encrypt() {
            this.params.whiteListId = this.reqData.whiteListId
            const req = await indexApi.encryptReceiveInfo(this.params)
            if (req.data.code === 200) {
                this.encryptObj = req.data.data
                window.location.href =
                this.reqData.url + "?cId=HLY&busIo=HLY001&cipText=" +
                    req.data.data;
            }else{
                Toast( req.data.msg )
            }
        }
    }
}
</script>

<style scoped lang="scss"></style>
 
