<template>
  <div>
    <van-nav-bar title="选择领取渠道" />
    <van-form @submit="submit">
      <van-field v-model="result" is-link readonly name="picker" label="渠道" placeholder="点击选择渠道"
        @click="showPicker = true" />
      <van-popup v-model="showPicker" position="bottom">
        <van-picker title="标题" show-toolbar :columns="data" @confirm="onConfirm" @cancel="showPicker = false" />
      </van-popup>
      
      <div style="margin: 16px">
        <van-button round block type="primary" native-type="submit">
          去领取
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import indexApi from '@/api/index'
import { Toast } from 'vant';
export default {
  components: {
    Toast
  },
  props: {},
  data() {
    return {
      params: {
        page: 1,
        payload: {},
        size: 100,
      },
      result: '',
      showPicker: false,
      data: [],
      baseId: ''
    }
  },
  computed: {},
  created() {
    this.getWhiteList()
  },
  methods: {
    async getWhiteList() {
      const req = await indexApi.getWhiteList(params)
      if (req.data.code === 200) {
        req.data.data.records.forEach(item => {
          this.data.push({ text: item.channelName, value: item.whiteBaseId })
        });
      }
    },
    onConfirm(selectedOptions ) {
      console.log(selectedOptions)
      this.result = selectedOptions.text;
      this.baseId = selectedOptions.value;
      this.showPicker = false;
    },


    async getWhiteList() {
      const req = await indexApi.getWhiteList(this.params)
      if (req.data.code === 200) {
        req.data.data.records.forEach(item => {
          this.data.push({ text: item.channelName, value: item.whiteBaseId })
        });
      }
    },
    submit() {
      if (this.baseId == '') {
        Toast('请先选择渠道')
        return
      }
      this.$router.push({
        name: 'verify',
        query: { baseId: this.baseId }
      })
    }
  }
}
</script>

<style scoped lang="scss"></style>
