import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index.vue'
import Verify from '../views/verify.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index,
    meta:{
      title: '白名单赠险领取'
    }
  },  
  {
    path: '/verify',
    name: 'verify',
    component: Verify,
    meta:{
      title: '白名单赠险领取'
    }
  },
]

const router = new VueRouter({
  routes
})

export default router
