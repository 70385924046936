import axios from "@/request/api.request";

export default {
    verifyReceiveInfo(data){
        return axios.request({
            url: '/whiteList/v1/verifyReceiveInfo',
            method: 'post',
            cache: false,
            data: data
        })
    },
    encryptReceiveInfo(data){
        return axios.request({
            url: '/whiteList/v1/encryptReceiveInfo',
            method: 'post',
            cache: false,
            data: data
        })
    },
    getUserQrCode(data){
        return axios.request({
            url: '/whiteList/v1/fetchQrCode',
            method: 'post',
            cache: false,
            data: data
        })
    },
    getWhiteList(data){
        return axios.request({
            url: '/whiteList/v1/getWhiteList',
            method: 'post',
            cache: false,
            data: data
        })
    },
}